import React from 'react';
//import {makeStyles} from '@mui/styles';
import {
    Container,
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText, createTheme, Box, Card
} from "@mui/material";

// const useStyles = makeStyles((theme:Theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
// }));

const theme = createTheme();

export default function AboutMe() {

    return (
        <>
            <Container sx={{p:theme.spacing(3)}} maxWidth="lg">
                <Box sx={{display:"flex",flexWrap:"wrap"}}>
                    <Card sx={{m:theme.spacing(2),width:{xs:"100%"}}}>
                        <Paper sx={{
                            height:1,
                            padding: theme.spacing(2),
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                        }}>
                            <Typography variant="h4" gutterBottom>
                                Mayiaz Abuarabi
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Computer Engineer
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                I am a computer engineer with over 3 years of experience in the field. My expertise
                                lies in
                                Java, C, C++ and C# programming languages, as well as SQL databases, Android
                                development,
                                React, and NodeJS. I have also worked with various other technologies such as Typo3,
                                PHP,
                                JavaScript, HTML, CSS, and SASS. I am also proficient in Linux and have experience with
                                Docker and GIT.
                            </Typography>
                            <br/>
                            <Typography variant="body1" gutterBottom>
                                I also have experience with networking and IOT devices using AVR Microcontrollers, Arduino and Raspberry PI and I'm a DIYer.
                            </Typography>
                        </Paper>
                    </Card>
                    <Card sx={{m:theme.spacing(2),width:{xs:"100%", lg:"47%"}}}>
                        <Paper sx={{
                            height:1,
                            padding: theme.spacing(2),
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                        }}>
                            <Typography variant="h5" gutterBottom>
                                Languages
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Arabic" secondary="native"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="German" secondary="C1"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="English" secondary="C1.1"/>
                                </ListItem>
                            </List>
                        </Paper>
                    </Card>
                    <Card sx={{m:theme.spacing(2),width:{xs:"100%", lg:"47%"}}}>
                        <Paper sx={{
                            height:1,
                            padding: theme.spacing(2),
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                        }}>
                            <Typography variant="h5" gutterBottom>
                                Work Experience
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="System Engineer (Student Assistant), USU Technologies GmbH"
                                                  secondary="October 2021 - Present"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="IT Support (Student Assistant), RWTH - EON"
                                                  secondary="September 2021 - March 2022"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Student Assistant, IVU" secondary="May 2018 - October 2020"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Intern, BITBURG IT" secondary="September 2017"/>
                                </ListItem>
                            </List>
                        </Paper>
                    </Card>
                    <Card sx={{m:theme.spacing(2),width:{xs:"100%", lg:"47%"}}}>
                        <Paper sx={{
                            height:1,
                            padding: theme.spacing(2),
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                        }}>
                            <Typography variant="h5">Education</Typography>
                            <List>
                                <ListItemText
                                    primary="Master's degree in Electrical Engineering, Information Technology, and Computer Engineering, RWTH Aachen University"
                                    secondary="October 2022 - Present"/>
                                <ListItemText
                                    primary="Bachelor's degree in Electrical Engineering, Information Technology, and Computer Engineering, RWTH Aachen University"
                                    secondary="October 2017 - December 2021"/>
                                <ListItemText primary="German Language Course"
                                              secondary="November 2016 - August 2017"/>
                                <ListItemText
                                    primary="Bachelor's degree in Computer and Automation Engineering, Damascus University"
                                    secondary="September 2013 - September 2016 (incomplete)"/>
                            </List>
                        </Paper>
                    </Card>

                </Box>
            </Container>

            <footer>
                <Typography variant="body2" color="white" align="center">
                    <Box sx={{backgroundColor:"rgba(0,0,0,.5)",width:"max-content", mx: "auto", px: "8px", py: "4px"}}>Made by Mayiaz Abuarabi (2023)</Box>
                </Typography>
            </footer>
        </>
    );
}
